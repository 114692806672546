<template>
  <!--begin::Quotation Listing-->
  <div class="visit-trip-template">
    <!--   <PageHeaderCount
        moduleType="quotation"
        :dataLoading="dataLoading"
        :statusList="statusList"
        allkey="all_quotations"
        countkey="quotation_status_count"
      ></PageHeaderCount> -->
    <ListingTemplate :customClass="'visit-trip-listing border-none'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 my-auto">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('quotation:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateBulkAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex
                class="pt-0 pr-0 justify-flex-end d-flex quotation-listing-button-right"
              >
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      depressed
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                          v-on:change="updateVisible()"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>

                <v-menu
                  v-if="false"
                  max-height="400"
                  max-width="260"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="
                            (cols.fixed == 1 ? true : false) || dataLoading
                          "
                          color="cyan"
                          v-on:change="updateVisible()"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <!--   <ListingSearchCriteria
          v-if="searchEnabled && false"
          pageModule="Quotation"
          :basicSearchFields="[
            'barcode',
            'reference',
            'job_title',
            'sales',
            'prepared_by',
            /* 'attention', */
            'company_name',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria> -->
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="quotation-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <div>
                            <Barcode
                              :barcode="data.barcode"
                              :id="data.id"
                            ></Barcode>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'dates'">
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.trip_start"
                          >
                            <b>Start: </b> {{ formatDate(data.trip_start) }}
                            {{ formatDateTimeStart(data.trip_start) }}
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.trip_end"
                          >
                            <b>End: </b> {{ formatDate(data.trip_end) }}
                            {{ formatDateTimeStart(data.trip_end) }}
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'service_form'">
                          <template
                            v-if="
                              data.service_forms &&
                              data.service_forms.length &&
                              data.service_forms.length > 0
                            "
                          >
                            <div>
                              <v-chip
                                color="chip-custom-blue text-capitalize"
                                class="mr-1 font-weight-700"
                                outlined
                                label
                                small
                              >
                                {{ data.service_forms[0].finalService }}
                              </v-chip>
                              <div
                                class="font-size-16 font-weight-700"
                                v-if="data.service_forms.length > 1"
                              >
                                <v-chip
                                  small
                                  color="blue white--text"
                                  label
                                  v-on:click="goToServiceForm(data.id)"
                                >
                                  <span class="font-size-16 font-weight-500">
                                    +{{
                                      data.service_forms.length - 1
                                    }}
                                    More</span
                                  >
                                </v-chip>
                                <!--   +{{ data.service_forms.length - 1 }} More -->
                              </div>
                            </div>
                          </template>
                          <em class="text-muted" v-else> no service form</em>
                        </template>
                        <template v-else-if="cols.field == 'engineers'">
                          <div
                            class="quotation-listing-customer d-flex"
                            v-if="data && data.engineers && data.engineers[0]"
                          >
                            <div>
                              <v-list-item-avatar
                                size="50"
                                style="margin-right: unset !important"
                              >
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-img
                                      v-if="
                                        data &&
                                        data.engineers[0] &&
                                        data.engineers[0].engineer
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                      :lazy-src="$defaultProfileImage"
                                      :src="
                                        $assetAPIURL(
                                          data.engineers[0].engineer
                                            .profile_logo
                                        )
                                      "
                                      aspect-ratio="1"
                                      class="margin-auto white--background"
                                      transition="fade-transition"
                                    ></v-img>
                                  </template>
                                  <span
                                    >{{
                                      data.engineers[0].engineer.display_name
                                    }}
                                  </span>
                                </v-tooltip>
                              </v-list-item-avatar>
                            </div>
                            <div class="ml-2">
                              <template
                                v-if="data.engineers[0].engineer.barcode"
                              >
                                <div>
                                  <label class="font-size-14 mb-0"
                                    ><b>
                                      <v-icon size="18">mdi-pound</v-icon>
                                    </b></label
                                  >
                                  {{ data.engineers[0].engineer.barcode }}
                                </div>
                              </template>

                              <template
                                v-if="data.engineers[0].engineer.display_name"
                              >
                                <div>
                                  <label class="font-size-14 mb-0"
                                    ><b>
                                      <v-icon size="18"
                                        >mdi-account-circle
                                      </v-icon>
                                    </b></label
                                  >
                                  {{ data.engineers[0].engineer.display_name }}
                                </div>
                              </template>
                              <template
                                v-if="data.engineers[0].engineer.primary_email"
                              >
                                <div>
                                  <label class="font-size-14 mb-0"
                                    ><b>
                                      <v-icon size="18">mdi-email</v-icon>
                                    </b></label
                                  >
                                  {{ data.engineers[0].engineer.primary_email }}
                                </div>
                              </template>
                            </div>
                          </div>
                          <template
                            v-if="
                              data.engineers &&
                              data.engineers.length &&
                              data.engineers.length > 1
                            "
                          >
                            <div style="margin-left: 50px">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-2"
                                v-on:click="openEnineers(data.engineers, data)"
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{ data.engineers.length - 1 }} More</span
                                >
                              </v-chip>
                            </div>
                          </template>
                        </template>
                        <template v-else-if="cols.field == 'action'">
                          <div class="quotation-listing-status">
                            <template v-if="data.visit_status == 1">
                              <v-chip
                                label
                                small
                                color="red"
                                outlined
                                class="mb-1"
                              >
                                Hold & CheckOut
                              </v-chip>
                            </template>
                            <template v-if="data.visit_status == 2">
                              <v-chip
                                label
                                small
                                color="red"
                                outlined
                                class="mb-1"
                              >
                                Hold & STAY
                              </v-chip>
                            </template>
                            <template v-if="data.visit_status == 3">
                              <v-chip
                                label
                                small
                                color="red"
                                outlined
                                class="mb-1"
                              >
                                Closer
                              </v-chip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatDateStartTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="6" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no trip at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
      </template>
    </ListingTemplate>
    <Dialog :common-dialog="show_engineers">
      <template v-slot:title>Trip({{ trip_barcode }}) :- Technicians</template>
      <template v-slot:body>
        <v-container fluid class="py-0">
          <v-row>
            <v-col md="12" class="pt-0 custom-listing-table">
              <template v-for="(row, EIndex) in trip_engineers">
                <table width="100%" class="white-background" :key="EIndex">
                  <tbody class="custom-border-bottom">
                    <tr>
                      <td
                        class="simple-table-td"
                        v-on:click="viewEngineer(row.engineer)"
                      >
                        <div class="quotation-listing-customer d-flex">
                          <div>
                            <v-list-item-avatar
                              size="50"
                              style="margin-right: unset !important"
                            >
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-img
                                    v-if="row.engineer"
                                    v-bind="attrs"
                                    v-on="on"
                                    :lazy-src="$defaultProfileImage"
                                    :src="
                                      $assetAPIURL(row.engineer.profile_logo)
                                    "
                                    aspect-ratio="1"
                                    class="margin-auto white--background"
                                    transition="fade-transition"
                                  ></v-img>
                                </template>
                                <span>{{ row.engineer.display_name }} </span>
                              </v-tooltip>
                            </v-list-item-avatar>
                          </div>
                          <div class="ml-2">
                            <template v-if="row.engineer.barcode">
                              <div>
                                <label class="font-size-14 mb-0"
                                  ><b>
                                    <v-icon size="18">mdi-pound</v-icon>
                                  </b></label
                                >
                                {{ row.engineer.barcode }}
                              </div>
                            </template>

                            <template v-if="row.engineer.display_name">
                              <div>
                                <label class="font-size-14 mb-0"
                                  ><b>
                                    <v-icon size="18"
                                      >mdi-account-circle
                                    </v-icon>
                                  </b></label
                                >
                                {{ row.engineer.display_name }}
                              </div>
                            </template>
                            <template v-if="row.engineer.primary_email">
                              <div>
                                <label class="font-size-14 mb-0"
                                  ><b>
                                    <v-icon size="18">mdi-email</v-icon>
                                  </b></label
                                >
                                {{ row.engineer.primary_email }}
                              </div>
                            </template>
                            <template v-if="row.engineer.primary_phone">
                              <div>
                                <label class="font-size-14 mb-0"
                                  ><b>
                                    <v-icon size="18">mdi-phone</v-icon>
                                  </b></label
                                >
                                {{ row.engineer.primary_phone }}
                              </div>
                            </template>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="show_engineers = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </div>

  <!--end::Quotation Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
/* import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue"; */
//import { map } from "lodash";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ListingMixin],
  name: "visit-trip-list",
  data() {
    return {
      dates: [],
      pageModule: "visit-trip",
      routeAPI: `visit/${this.visitId}/trips`,
      importDialog: false,
      show_engineers: false,
      trip_barcode: null,
      status: "all",
      statusList: [],
      trip_engineers: [],
      customThead: [],
      moreActions: [
        {
          title: "Import Quotation(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        /*{
                  title: "Export Quotation(s)",
                  action: "export_items",
                  divider: false,
                  icon: "mdi-database-export",
                },*/
        /*{
                  title: "Export Current View",
                  action: "export_current_view",
                  divider: true,
                  icon: "mdi-database-export",
                },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Draft",
          icon: "mdi-check-all",
          action: "draft",
        },
        {
          title: "Mark as Accepted",
          icon: "mdi-check-all",
          action: "accepted",
        },
        {
          title: "Mark as Rejected",
          icon: "mdi-check-all",
          action: "rejected",
        },
        {
          title: "Mark as Pending",
          icon: "mdi-email-receive",
          action: "pending",
        },
        /*{
                  title: "Send as Email",
                  icon: "mdi-email-send",
                  action: "sent"
                },*/
        {
          title: "Mark as Cancelled",
          icon: "mdi-check-all",
          action: "cancelled",
        },
        {
          title: "Mark as Closed",
          icon: "mdi-check-all",
          action: "closed",
        },
      ],
    };
  },
  components: {
    /*   PageTips, */
    draggable,
    Dialog,
    /*  PageHeaderCount, */
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    /*  ListingSearchCriteria, */
  },
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    visitId: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    goToServiceForm(id) {
      let query = { ...this.$route.query };
      query.tab = "service-form";
      query.trip_id = id;
      this.$router.push({ query });
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    openEnineers(engineers, tripData) {
      this.trip_barcode = tripData.barcode;
      this.trip_engineers = engineers;
      this.show_engineers = true;
    },
    viewEngineer(params) {
      this.$router.push(
        this.getDefaultRoute("engineer.detail", {
          params: { id: params.id },
        })
      );
    },
  },
  mounted() {},
  beforeMount() {
    const _this = this;

    //_this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
